import { Link } from "gatsby"

import LastArrowIcon from "../../images/svg/last.inline.svg"

const LastPageLink = ({ link }) => {
  return (
    <Link
      to={link}
      aria-labelledby="lastLabel"
      sx={{
        variant: "links.pagination",
        height: "2rem",
        alignContent: "center"
      }}
    >
      <LastArrowIcon sx={{ variant: "icons.arrow" }} />
    </Link>
  )
}

export default LastPageLink
