import { Link } from "gatsby"

import { useMediaQuery } from "../../hooks/use-media-query"

import NextArrowIcon from "../../images/svg/next.inline.svg"

const NextPageLink = ({ link, story }) => {
  const isDesktop = useMediaQuery("(min-width: 640px)")
  const label = story ? (isDesktop ? `next` : ``) : `next`
  return (
    <Link
      to={link}
      aria-labelledby="nextLabel"
      rel="next"
      sx={{
        variant: "links.paginationGrid"
      }}
    >
      {label} <NextArrowIcon sx={{ variant: "icons.arrow" }} />
    </Link>
  )
}

export default NextPageLink
