import { Link } from "gatsby"

import FirstArrowIcon from "../../images/svg/first.inline.svg"

const FirstPageLink = ({ link }) => {
  return (
    <Link
      to={link}
      aria-labelledby="firstLabel"
      sx={{
        variant: "links.pagination",
        height: "2rem",
        alignContent: "center"
      }}
    >
      <FirstArrowIcon sx={{ variant: "icons.arrow" }} />
    </Link>
  )
}

export default FirstPageLink
