import { useState, useEffect } from "react"

export const useMediaQuery = (query) => {
  const [isSize, setSize] = useState(false)
  const handleSizeChange = ({ matches }) => setSize(matches)
  useEffect(() => {
    // Window does not exist on SSR
    if (typeof window !== "undefined") {
      const mql = window.matchMedia(query)
      mql.addListener(handleSizeChange)
      setSize(mql.matches) // Set initial state in DOM
      return () => mql.removeListener(handleSizeChange)
    }
  }, [query])
  return isSize
}
