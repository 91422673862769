import { Link } from "gatsby"

import { useMediaQuery } from "../../hooks/use-media-query"

import PrevArrowIcon from "../../images/svg/prev.inline.svg"

const PrevPageLink = ({ link, story }) => {
  const isDesktop = useMediaQuery("(min-width: 640px)")
  const label = story ? (isDesktop ? `prev` : ``) : `prev`

  return (
    <Link
      to={link}
      aria-labelledby="prevLabel"
      rel="prev"
      sx={{
        variant: "links.paginationGrid"
      }}
    >
      <PrevArrowIcon sx={{ variant: "icons.arrow" }} /> {label}
    </Link>
  )
}

export default PrevPageLink
